import { Component } from '@angular/core';
import { environment } from '@configs/environments/environment';
import { Branch } from '@configs/environments/environment.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: false,
})
export class AppComponent {
    isMainBranch: boolean = environment.branch === Branch.MAIN;
}
