import { ButtonModule } from 'primeng/button';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

import { TopBarComponent } from './top-bar.component';

@NgModule({
    declarations: [TopBarComponent],
    exports: [TopBarComponent],
    imports: [BrowserModule, CommonModule, RouterLink, ButtonModule],
})
export class TopBarModule {}
