import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';

import { environment } from '../../config/environments/environment';
import { ApiModule } from '../shared/openapi/api.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { TopBarModule } from './top-bar/top-bar.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),
        TopBarModule,
        ApiModule.forRoot({ rootUrl: environment.rootUrl }),
    ],
    exports: [AppComponent],
    providers: [
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        provideAuth0(environment.auth0.config),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
