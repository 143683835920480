import { CollapseDirective, CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLink } from '@angular/router';
import { FooterModule } from '@web/angular-components/footer/footer.module';

import { TopBarComponent } from './top-bar.component';

@NgModule({
    declarations: [TopBarComponent],
    exports: [TopBarComponent],
    imports: [
        BrowserModule,
        CommonModule,
        RouterLink,
        FooterModule,
        BrowserAnimationsModule,
        BsDropdownModule,
        CollapseModule,
        CollapseDirective,
    ],
})
export class TopBarModule {}
