@if(validPassword) {
<div class="flex justify-content-center uppercase text-red-300 bg-black-alpha-90" *ngIf="isMainBranch">-- Main Branch -
    Preview Version -
    Internal
    Use
    Only --
</div>
<app-top-bar></app-top-bar>
<router-outlet></router-outlet>
<app-footer></app-footer>
}@else{

<div class="flex align-items-center justify-content-center h-screen">
    <p-panel>
        <div class="field p-5">
            <label for="passwor" class="block mb-2">Password</label>
            <input type="password" id="password" name="password" (input)="checkPassword($event)" pInputText />
        </div>
    </p-panel>
</div>
}