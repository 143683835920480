<div id="topbar" class="flex scrolled py-2 px-5 align-items-center justify-content-between fixed w-full">
    <div class="valid flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="248" height="50.385" viewBox="0 0 248 50.385">
            <g id="Gruppe_12021" data-name="Gruppe 12021" transform="translate(-924 -272.999)">
                <path id="Pfad_2" data-name="Pfad 2"
                    d="M0,2.538H3.728V1.77H.768V-1.5H0Zm7.453,0H9.082l.03-4.027L8.292-1.5,8.284,2.449l-2-3.938L4.639-1.5,4.631,2.538h.793l.03-3.963Zm2.626.046H11.81A2,2,0,0,0,13.945.533,1.968,1.968,0,0,0,11.861-1.5H10.079Zm.739-3.378h1.027A1.276,1.276,0,0,1,13.2.522a1.3,1.3,0,0,1-1.377,1.337l-1.008.005Z"
                    transform="translate(1158.056 274.496)" fill="#fff" />
                <path id="Pfad_4" data-name="Pfad 4"
                    d="M23.551,31.639,40.959-18.586H30.942L13.682,31.127h-.294L-3.872-18.586H-13.889L3.592,31.639Zm74.51,0h46.5V22.061h-39.48L90.758-18.659H80.313Z"
                    transform="translate(937.889 291.709)" fill="#fff" />
                <path id="Pfad_5" data-name="Pfad 5" d="M576.295-719h9.583v-9.583h-9.583Z"
                    transform="translate(515.63 1042.349)" fill="#fff" />
                <path id="Pfad_6" data-name="Pfad 6"
                    d="M23.551,0,40.959,50.225H30.942L13.682.512h-.294L-3.872,50.225H-13.889L3.592,0Z"
                    transform="translate(985.027 273.122)" fill="#fff" />
                <path id="Pfad_7" data-name="Pfad 7" d="M6.025,0h-9.5l-.073,32.639H5.806Z"
                    transform="translate(1095.54 273)" fill="#fff" />
                <path id="Pfad_8" data-name="Pfad 8"
                    d="M0,31.693H21.355c13.031,0,26.35-8.108,26.35-25.336,0-16.794-12.74-25.048-25.7-25.048H0ZM9.122-10H21.789C29.9-10,38.583-5.081,38.583,6.211c0,10.715-7.963,16.505-17.009,16.505l-12.451.073Z"
                    transform="translate(1111 291.691)" fill="#fff" />
            </g>
        </svg>
    </div>
    <div [ngClass]="{'hidden':!navToggle}" class="navbar-nav justify-content-between flex-row align-items-center gap-5 md:flex">
        <a routerLink="/validate" (click)="navToggle=!navToggle">Val!date</a>
        <a routerLink="/page-check" (click)="navToggle=!navToggle" >Page Check</a>
        <a routerLink="/id-check" (click)="navToggle=!navToggle">ID Check</a>
        @if (auth.user$ | async) {
            <a routerLink="/library">Bibliothek</a>
        }
    </div>

    <div class="flex flex-row align-items-center gap-2 justify-content-end">
        <p-button *ngIf="(auth.user$ | async) === null" severity="secondary" [text]="true"
            (click)="login()" class="btn-valid login">Login</p-button>
        @if (auth.user$ | async) {
        <p-button severity="secondary" [text]="true"
            routerLink="/account" class="btn-valid konto">Konto</p-button>
        <!-- <p-button severity="secondary" [text]="true"
            (click)="logout()" class="btn-valid d-none">Logout</p-button> -->
        }
        <button class="hamburger hamburger--spin md:hidden" type="button"
            [ngClass]="{'is-active':navToggle}"
            (click)="navToggle=!navToggle">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
        </button>  
    </div>
</div>