<div class="footer py-5">
    <div class="container">
        <div class="row mb-2">
            <div class="col-12 text-center d-flex flex-row gap-2 align-items-center justify-content-center">
                <div class="font-semibold small">powered by</div>
                <svg class="eye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.491 8.326">
                    <path
                        d="M0,0C-.279-.235-.6-.488-.962-.742A9.339,9.339,0,0,0-6.243-2.63,9.34,9.34,0,0,0-11.525-.742c-.362.255-.686.51-.966.745v3.06c.28.236.6.491.966.746A9.346,9.346,0,0,0-6.243,5.7,9.345,9.345,0,0,0-.962,3.809C-.6,3.555-.279,3.3,0,3.067ZM-6.217-1.346A1.987,1.987,0,0,1-4.232.639,1.986,1.986,0,0,1-6.217,2.623,1.986,1.986,0,0,1-8.2.639,1.987,1.987,0,0,1-6.217-1.346m-.026,5.822A8.287,8.287,0,0,1-10.8,2.828a13.137,13.137,0,0,1-1.567-1.3A12.147,12.147,0,0,1-9.112-.735,3.192,3.192,0,0,0-9.421.639a3.208,3.208,0,0,0,3.2,3.205A3.208,3.208,0,0,0-3.012.639,3.19,3.19,0,0,0-3.305-.7,10.717,10.717,0,0,1-1.689.239,13.146,13.146,0,0,1-.122,1.533c-.918.89-3.339,2.943-6.121,2.943"
                        transform="translate(12.491 2.63)" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.184 3.303" class="layonardo">
                    <g transform="translate(-515.411 -1319.837)">
                        <path
                            d="M0,0H2.631V-.577H.609V-3.031H0ZM7.534,0h.7L9.968-3.031H9.276L8.347-1.353,7.39-3.031H6.706l.943,1.678h.7Zm8.595,0h1.225l.022-3.027-.617,0L16.754-.066l-1.38-2.961-1.238,0,0,3.031h.6l.022-2.978Zm6.4-3.031V0h.582V-.8h1.049l.526.8h.67l-.621-.908a1.13,1.13,0,0,0,.671-1.048A1.092,1.092,0,0,0,24.321-3.04Zm.582.5h1.11a.586.586,0,0,1,.6.573.612.612,0,0,1-.6.617h-1.11ZM26.261.035H27.6a1.506,1.506,0,0,0,1.6-1.542,1.478,1.478,0,0,0-1.564-1.524H26.261ZM26.816-2.5h.815a.959.959,0,0,1,1.022.987A.975.975,0,0,1,27.617-.511l-.8,0Z"
                            transform="translate(515.411 1322.99)" />
                        <path
                            d="M0,0-1.227-3.033H-2.42L-3.647,0h.614l.325-.8H-.939l.325.8ZM-1.162-1.356H-2.485l.662-1.634Z"
                            transform="translate(522.237 1322.992)" />
                        <path
                            d="M0,0-1.227-3.033H-2.42L-3.647,0h.614l.325-.8H-.939l.325.8ZM-1.162-1.356H-2.485l.662-1.634Z"
                            transform="translate(537.189 1322.992)" />
                        <path
                            d="M0,0A1.653,1.653,0,0,1-1.651-1.651,1.653,1.653,0,0,1,0-3.3,1.653,1.653,0,0,1,1.651-1.651,1.653,1.653,0,0,1,0,0M0-2.758A1.108,1.108,0,0,0-1.107-1.651,1.109,1.109,0,0,0,0-.544,1.109,1.109,0,0,0,1.107-1.651,1.108,1.108,0,0,0,0-2.758"
                            transform="translate(546.945 1323.14)" />
                        <path
                            d="M0,0A1.653,1.653,0,0,1-1.651-1.651,1.653,1.653,0,0,1,0-3.3,1.653,1.653,0,0,1,1.651-1.651,1.653,1.653,0,0,1,0,0M0-2.758A1.108,1.108,0,0,0-1.107-1.651,1.109,1.109,0,0,0,0-.544,1.109,1.109,0,0,0,1.107-1.651,1.108,1.108,0,0,0,0-2.758"
                            transform="translate(527.18 1323.14)" />
                    </g>
                </svg>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center small mt-3">
                <a href="https://www.wkoecg.at/Ecg.aspx?FirmaID=3b83adb5-100e-4848-8778-0dc187a69b46" target="_blank" rel="nofollow">Imprint</a>
            </div>
        </div>
    </div>
</div>