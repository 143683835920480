import { Branch, Environment } from './environment.model';

export const environment: Environment = {
    branch: Branch.MAIN,
    rootUrl: 'https://backend-layonardo-dev.azurewebsites.net',
    auth0: {
        config: {
            domain: 'layonardo-dev.eu.auth0.com',
            clientId: 'qsEiv755EC9wMpMuRB6IG96UWy9gmS9S',
            authorizationParams: {
                audience: 'https://layonardo-dev',
                redirect_uri: window.location.origin,
                // screen_hint: 'signup',
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: '*',
                    },
                ],
            },
        },
    },
};
