import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'validate', pathMatch: 'full' },
    {
        path: 'validate',
        loadChildren: (): Promise<any> =>
            import('./valid/valid.module').then((m): any => m.ValidModule),
    },
    {
        path: 'page-check',
        loadChildren: (): Promise<any> =>
            import('./page-check/page-check.module').then(
                (m): any => m.PageCheckModule
            ),
    },
    {
        path: 'id-check',
        loadChildren: (): Promise<any> =>
            import('./id-check/id-check.module').then(
                (m): any => m.IdCheckModule
            ),
    },
    {
        path: 'library',
        loadChildren: (): Promise<any> =>
            import('./library/library.module').then(
                (m): any => m.LibraryModule
            ),
    },

    {
        path: 'account',
        loadChildren: (): Promise<any> =>
            import('./account/account.module').then(
                (m): any => m.AccountModule
            ),
    },
];
