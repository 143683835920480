import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrl: './top-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
    constructor(public auth: AuthService) {}

    login(): void {
        this.auth.loginWithRedirect();
    }

    logout(): void {
        this.auth.logout();
    }
}
