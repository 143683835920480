import { Component } from '@angular/core';

import { environment } from '../../config/environments/environment';
import { Branch } from '../../config/environments/environment.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    isMainBranch: boolean = environment.branch === Branch.MAIN;

    validPassword = !!localStorage.getItem('validPassword');

    checkPassword(event: any) {
        console.log(event);
        if (event.target.value === 'monalisa') {
            this.validPassword = true;
            localStorage.setItem('validPassword', 'true');
        }
    }
}
