<nav id="topbar" class="navbar navbar-expand-md navbar-dark scrolled fixed-top">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
            <div class="col-12 text-center d-flex flex-row gap-3 align-items-center justify-content-center">
                <div routerLink="/">Logo</div>
            </div>
        </a>

        <div class="collapse navbar-collapse d-md-flex justify-content-md-center ps-3 ps-md-0 order-3 order-md-2"
            [collapse]="isCollapsed" [isAnimated]="true">
            <ul class="navbar-nav gap-4">
                <li class="nav-item">
                    <a routerLink="/validate" (click)="navToggle=!navToggle">Val!date Image</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/pricing" (click)="navToggle=!navToggle">Abos</a>
                </li>

            </ul>
        </div>

        <div class="ms-auto order-2 order-md-3 d-flex align-items-center gap-2">
            <button *ngIf="(auth.user$ | async) === null" (click)="login()" class="btn btn-valid login">Login</button>
            <button *ngIf="(auth.user$ | async)" routerLink="/account" class="btn btn-valid konto">Konto</button>
            <button *ngIf="(auth.user$ | async)" (click)="logout()" class="btn btn-valid">Logout</button>

            <button class="hamburger hamburger--collapse d-md-none mt-1 ms-2" type="button"
                [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed"
                [class.is-active]="!isCollapsed">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
</nav>