import { Route } from '@angular/router';

import { ACCOUNT_ROUTE_PATHS } from './subpages/account/account-route-paths.model';
import { LANDING_PAGE_ROUTE_PATHS } from './subpages/landing-page/landing-page-route-paths.model';
import { PRICING_ROUTE_PATHS } from './subpages/pricing/pricing-route-paths.model';
import { VALIDATE_IMAGE_ROUTE_PATHS } from './subpages/validate-image/validate-image-route-paths.model';

export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo: LANDING_PAGE_ROUTE_PATHS.LANDING_PAGE,
        pathMatch: 'full',
    },
    {
        path: VALIDATE_IMAGE_ROUTE_PATHS.VALIDATE,
        loadChildren: (): Promise<any> =>
            import('./subpages/validate-image/validate-image.module').then(
                (m): any => m.ValidateImageModule,
            ),
    },
    {
        path: LANDING_PAGE_ROUTE_PATHS.LANDING_PAGE,
        loadChildren: (): Promise<any> =>
            import('./subpages/landing-page/landing-page.module').then(
                (m): any => m.LandingPageModule,
            ),
    },
    {
        path: PRICING_ROUTE_PATHS.PRICING,
        loadChildren: (): Promise<any> =>
            import('./subpages/pricing/pricing.module').then(
                (m): any => m.PricingModule,
            ),
    },
    {
        path: ACCOUNT_ROUTE_PATHS.ACCOUNT,
        loadChildren: (): Promise<any> =>
            import('./subpages/account/account.module').then(
                (m): any => m.AccountModule,
            ),
    },
];
