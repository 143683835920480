import { AuthConfig } from '@auth0/auth0-angular';

export enum Branch {
    MAIN = 'main',
    RELEASE = 'release',
}

export interface Environment {
    auth0: { config: AuthConfig };
    branch: Branch;
    rootUrl: string;
}
